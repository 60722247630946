<template>
  <div>
    <KTCard card-class="card-table">
      <OurTable
        data-cy="transactions-table"
        table-classes="our-table table-responsive-xl"
        :pagination-data="paginationData"
        :items="agentTransactions"
        :fields="fields"
        :filters="appliedFilters"
        @pageChange="onPageChange"
      />
    </KTCard>

    <PageModal ref="filtersModal" modal-id="filtersModal" :title="$t('label.filters')" is-centered>
      <OurFilters
        ref="ourFilters"
        :fields="fieldsForFilters"
        :applied-filters="appliedFilters"
        @applyFilters="onApplyFilters"
      />

      <template v-slot:footer>
        <div class="w-100 d-flex justify-content-between">
          <ButtonElement
            data-cy="find-with-filters"
            variant="primary"
            text="find"
            @click="onFindWithFilters"
          />

          <ButtonElement
            data-cy="reset-filters"
            variant="outline-primary"
            text="resetFilters"
            @click="onResetFilters"
          />
        </div>
      </template>
    </PageModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { TYPE_VALUES } from "@/api/payments";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import PageModal from "@/components/modal/PageModal";
import OurFilters from "@/components/OurFilters";
import ButtonElement from "@/components/ButtonElement";

const { transactions } = TYPE_VALUES;

export default {
  name: "TransactionList",
  components: {
    KTCard,
    OurTable,
    PageModal,
    OurFilters,
    ButtonElement,
  },

  props: {
    paginationData: {
      type: Object,
      default: () => ({}),
    },

    transactions: {
      type: Array,
      default: () => [],
    },

    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "amount",
          label: this.$t("label.amount"),
          type: "amount",
          thClass: "text-right",
          tdAttr: {
            style: "width: 20%; max-width: 90px; text-align: right;",
          },
          filter: {
            component: "input",
            type: "number",
            operator: "like",
            classes: "w-50",
          },
        },
        {
          key: "type",
          label: this.$t("label.type"),
          tdAttr: {
            style: "width: 30%; max-width: 100px;",
          },
          filter: {
            component: "select",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "order",
          label: this.$t("label.order._"),
          type: "link",
          tdAttr: {
            style: "width: 40%; max-width: 110px;",
          },
          filter: {
            component: "input",
            type: "text",
            column: "referral.name",
            operator: "like",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("transaction", ["types"]),

    agentTransactions() {
      return this.transactions.map((transaction) => {
        const dateCreate = transaction.createdAt;
        const amount = {
          amount: transaction.amount,
          type: transaction.type,
        };
        const type = this.types.find((type) => type.code === transaction.type)?.label;
        let order = "–";

        if (transaction.referral) {
          order = {
            title: transaction.referral.name,
            route: {
              name: "ReferralDetails",
              params: { id: transaction.referral.id },
            },
          };
        }

        return {
          dateCreate,
          amount,
          type,
          order,
        };
      });
    },

    fieldsForFilters() {
      return this.fields.map((field) => {
        if (field.key === "type") {
          field.filter.selectOptions = this.types;
          field.filter.trackBy = "code";
        }

        return field;
      });
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    showFiltersModal() {
      this.$refs.filtersModal.showModal();
    },

    onFindWithFilters() {
      this.$refs.ourFilters.applyFilters();
      this.$refs.filtersModal.closeModal();
    },

    onApplyFilters(filters) {
      this.$emit("applyFilters", {
        type: transactions,
        fields: this.fields,
        filters,
      });
    },

    onResetFilters() {
      this.$emit("resetFilters", transactions);

      this.$refs.filtersModal.closeModal();
    },
  },
};
</script>
