<template>
  <b-form class="requisites-form" novalidate>
    <InputElement
      v-model="form.lastName"
      data-cy="last-name-input"
      class="mb-10"
      required
      :with-error="errors.lastName"
      :error-message="$t('validation.required')"
      :label="$t('label.lastName')"
    />

    <InputElement
      v-model="form.firstName"
      data-cy="first-name-input"
      class="mb-10"
      required
      :with-error="errors.firstName"
      :error-message="$t('validation.required')"
      :label="$t('label.firstName')"
    />

    <InputElement
      v-model="form.middleName"
      data-cy="middle-name-input"
      class="mb-10"
      required
      :with-error="errors.middleName"
      :error-message="$t('validation.required')"
      :label="$t('label.middleName')"
    />

    <InputElement
      v-model="form.taxNumber"
      data-cy="tax-number-input"
      class="mb-10"
      required
      :with-error="errors.taxNumber"
      :error-message="taxNumberErrorMsg"
      :label="$t('label.taxNumber.full')"
    />

    <InputElement
      v-model="form.iban"
      data-cy="iban-input"
      class="mb-0"
      required
      :with-error="errors.iban"
      :error-message="ibanErrorMsg"
      :label="$t('label.iban')"
    />
  </b-form>
</template>

<script>
import InputElement from "@/components/form/InputElement.vue";
import ButtonElement from "@/components/ButtonElement.vue";

export default {
  name: "RequisitesForm",

  components: {
    InputElement,
    ButtonElement,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        middleName: "",
        taxNumber: "",
        iban: "",
      },
      errors: {
        firstName: false,
        lastName: false,
        middleName: false,
        taxNumber: false,
        iban: false,
      },
    };
  },

  computed: {
    taxNumberRegular() {
      const taxNumberRegExp = /^[0-9]{10,12}$/;

      return this.clearSpace(this.form.taxNumber).match(taxNumberRegExp);
    },

    ibanRegular() {
      const ibanRegExp = /^[a-zA-Z]{2}[0-9]{16,30}$/;

      return this.clearSpace(this.form.iban).match(ibanRegExp);
    },

    taxNumberErrorMsg() {
      let error;

      if (this.form.taxNumber && !this.taxNumberRegular) {
        error = this.$t("validation.taxNumberLength");
      } else if (!this.form.taxNumber) {
        error = this.$t("validation.required");
      }

      return error;
    },

    ibanErrorMsg() {
      let error;

      if (this.form.iban && !this.ibanRegular) {
        error = this.$t("validation.ibanLength");
      } else if (!this.form.iban) {
        error = this.$t("validation.required");
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      immediate: true,
      deep: false,
    },
  },

  methods: {
    getForm() {
      this.form = { ...this.value };
    },

    clearSpace(value) {
      const spaceReg = /\s/g;

      return value.replace(spaceReg, "");
    },

    isValidForm() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.lastName = !this.form.lastName;
      this.errors.middleName = !this.form.middleName;
      this.errors.taxNumber = !this.form.taxNumber;
      this.errors.iban = !this.form.iban;

      if (this.form.taxNumber) this.errors.taxNumber = !this.taxNumberRegular;
      if (this.form.iban) this.errors.iban = !this.ibanRegular;

      return !Object.values(this.errors).some((error) => error);
    },

    emitForm() {
      if (!this.isValidForm()) return;

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

<style scoped></style>
