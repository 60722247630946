import gql from "graphql-tag";

export const balanceData = gql`
  query balanceData {
    balance {
      amount
      accrued
      withdrawn
    }
  }
`;

export const withdrawalsData = gql`
  query withdrawalsData($orderBy: OrderBy, $filters: Filter, $page: Int, $perPage: Int) {
    withdrawals(orderBy: $orderBy, filters: $filters, page: $page, first: $perPage) {
      paginatorInfo {
        total
        perPage
        currentPage
        lastPage
      }

      data {
        id
        amount
        comment
        status
        createdAt
        user {
          id
        }
        createdBy {
          id
        }
      }
    }
  }
`;

export const transactionsData = gql`
  query transactionsData($orderBy: OrderBy, $filters: Filter, $page: Int, $perPage: Int) {
    transactions(orderBy: $orderBy, filters: $filters, page: $page, first: $perPage) {
      paginatorInfo {
        total
        perPage
        currentPage
        lastPage
      }

      data {
        id
        type
        amount
        comment
        createdAt
        referral {
          id
          name
        }
        withdrawal {
          id
          amount
        }
      }
    }
  }
`;
