<template>
  <div data-cy="payments-page">
    <div class="w-100 d-flex justify-content-between mb-4">
      <div class="balance-container">
        <ButtonElement
          data-cy="add-withdrawal-btn"
          class="withdrawal-btn"
          variant="primary"
          text="createWithdrawal"
          @click="onClickAddWithdrawal"
        />

        <div class="balance-wrap">
          <span class="font-weight-bold balance mr-2">{{ $t("label.balance._") }}:</span>
          <span data-cy="balance" class="font-weight-bold balance"> {{ agentBalance }} </span>
        </div>
      </div>

      <div class="filters-container">
        <ButtonElement
          data-cy="filters-btn"
          class="filters-btn"
          no-focus
          :variant="filtersBtnVariant()"
          text="filters"
          @click="onClickFilters"
        >
          <span>{{ $t("button.filters") }}</span>
          <span>{{ filtersCounter() }}</span>
        </ButtonElement>

        <b-form-radio-group
          v-model="currentTable"
          class="align-items-start transactions-radio"
          buttons
          button-variant="outline-primary"
        >
          <b-form-radio data-cy="withdrawals-radio" value="withdrawals">
            {{ $t("label.withdrawal.list") }}
          </b-form-radio>

          <b-form-radio data-cy="transactions-radio" value="transactions">
            {{ $t("label.operation.list") }}
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </div>

    <AddRequisitesModal
      ref="AddRequisitesModal"
      :requisite="requisite"
      @saveRequisite="saveRequisite"
    />

    <WithdrawalList
      v-if="isOpenedWithdrawalsTable"
      ref="withdrawals"
      :pagination-data="withdrawalsPaginationData"
      :withdrawals="withdrawals"
      :applied-filters="appliedWithdrawalsFilters"
      @pageChange="getWithdrawals"
      @applyFilters="onApplyFilters"
      @resetFilters="resetFilters"
    />

    <TransactionList
      v-else
      ref="transactions"
      :pagination-data="transactionsPaginationData"
      :transactions="transactions"
      :applied-filters="appliedTransactionsFilters"
      @pageChange="getTransactions"
      @applyFilters="onApplyFilters"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { balanceData, withdrawalsData, transactionsData } from "./gql/queries";
import { updateProfile, createWithdrawal } from "./gql/mutations";
import { TYPE_VALUES } from "@/api/payments";
import { serializeFilters } from "@/services/filterBuilder.service";

import AddRequisitesModal from "@/components/AddRequisitesModal.vue";
import TransactionList from "./components/TransactionList";
import WithdrawalList from "./components/WithdrawalList";
import ButtonElement from "@/components/ButtonElement";

const { withdrawals } = TYPE_VALUES;

const CONTENT_WIDTH = 700;

export default {
  name: "Payments",

  components: {
    AddRequisitesModal,
    TransactionList,
    WithdrawalList,
    ButtonElement,
  },

  mixins: [apolloMixin],

  data() {
    return {
      currentTable: withdrawals,
      sortingOptions: {
        column: "createdAt",
        direction: "desc",
      },
      balance: 0,
      requisite: {},
      withdrawalsPaginationData: {},
      withdrawals: [],
      transactionsPaginationData: {},
      transactions: [],
      fieldsWithdrawals: [],
      fieldsTransactions: [],
      appliedWithdrawalsFilters: {},
      appliedTransactionsFilters: {},
    };
  },

  computed: {
    ...mapState("agent", ["paymentsFilters"]),
    ...mapState("currency", ["currency"]),

    ...mapGetters(["userRequisites"]),

    agentBalance() {
      return `${this.balance} ${this.currency}`;
    },

    isOpenedWithdrawalsTable() {
      return this.currentTable === withdrawals;
    },
  },

  async created() {
    if (this.paymentsFilters.withdrawals) {
      this.appliedWithdrawalsFilters = this.paymentsFilters.withdrawals;
    }

    if (this.paymentsFilters.transactions) {
      this.appliedTransactionsFilters = this.paymentsFilters.transactions;
    }

    await Promise.all([
      this.getBalance(),
      this.getRequisite(),
      this.getWithdrawals(),
      this.getTransactions(),
    ]);
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapMutations("agent", ["SET_PAYMENTS_FILTERS"]),

    async getBalance() {
      const { balance } = await this.$get(balanceData);

      this.balance = balance.amount;
    },

    async getRequisite() {
      this.requisite = { ...this.userRequisites };
    },

    async getWithdrawals(page) {
      let filtersOptions = [];
      const isAppliedFilters = !!Object.keys(this.appliedWithdrawalsFilters).length;

      if (page && page === this.withdrawalsPaginationData.currentPage) return;

      if (isAppliedFilters) {
        filtersOptions = serializeFilters(this.fieldsWithdrawals, this.appliedWithdrawalsFilters);
      }

      const { withdrawals } = await this.$get(withdrawalsData, {
        filtersOptions,
        sortingOptions: this.sortingOptions,
        page,
      });

      this.withdrawalsPaginationData = withdrawals.paginatorInfo;
      this.withdrawals = withdrawals.data;
    },

    async getTransactions(page) {
      let filtersOptions = [];
      const isAppliedFilters = !!Object.keys(this.appliedTransactionsFilters).length;

      if (page && page === this.transactionsPaginationData.currentPage) return;

      if (isAppliedFilters) {
        filtersOptions = serializeFilters(this.fieldsTransactions, this.appliedTransactionsFilters);
      }

      const { transactions } = await this.$get(transactionsData, {
        filtersOptions,
        sortingOptions: this.sortingOptions,
        page,
      });

      this.transactionsPaginationData = transactions.paginatorInfo;
      this.transactions = transactions.data;
    },

    async getCurrentTableData() {
      this.currentTable === withdrawals
        ? await this.getWithdrawals()
        : await this.getTransactions();
    },

    onClickAddWithdrawal() {
      if (!this.requisite.iban) {
        this.$refs.AddRequisitesModal.showModal();

        return;
      }

      this.AddWithdrawal();
    },

    async AddWithdrawal() {
      const createdWithdrawal = await this.$post(createWithdrawal);

      if (createdWithdrawal) await this.getWithdrawals();
    },

    async saveRequisite(form) {
      await this.$post(updateProfile, form);
      await this.AddWithdrawal();
    },

    async onApplyFilters({ type, fields, filters }) {
      if (type === withdrawals) {
        this.fieldsWithdrawals = fields;
        this.appliedWithdrawalsFilters = filters;
      } else {
        this.fieldsTransactions = fields;
        this.appliedTransactionsFilters = filters;
      }

      this.SET_PAYMENTS_FILTERS({ type, filters });
      await this.getCurrentTableData();
    },

    async resetFilters(type) {
      if (type === withdrawals) {
        this.fieldsWithdrawals = [];
        this.appliedWithdrawalsFilters = {};
      } else {
        this.fieldsTransactions = [];
        this.appliedTransactionsFilters = {};
      }

      const filters = null;

      this.SET_PAYMENTS_FILTERS({ type, filters });
      await this.getCurrentTableData();
    },

    onClickFilters() {
      this.$refs[this.currentTable].showFiltersModal();
    },

    filtersBtnVariant() {
      const isAppliedFilters =
        this.currentTable === withdrawals
          ? !!Object.keys(this.appliedWithdrawalsFilters).length
          : !!Object.keys(this.appliedTransactionsFilters).length;

      return isAppliedFilters ? "dark" : "outline-dark";
    },

    filtersCounter() {
      const counter =
        this.currentTable === withdrawals
          ? Object.keys(this.appliedWithdrawalsFilters).length
          : Object.keys(this.appliedTransactionsFilters).length;

      return counter ? ` · ${counter}` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-container {
  display: flex;

  .withdrawal-btn {
    margin-right: 1.5rem;
  }

  .balance-wrap {
    display: flex;
    align-items: center;

    .balance {
      font-size: 16px;
    }
  }
}

.filters-container {
  display: flex;

  .filters-btn {
    margin-right: 1.5rem;
  }
}

@media (max-width: 575.98px) {
  .balance-container {
    flex-direction: column;

    .withdrawal-btn {
      margin-right: 0;
      margin-bottom: 0.75rem;
      white-space: nowrap;
    }

    .balance-wrap {
      height: 100%;
    }
  }

  .filters-container {
    flex-direction: column-reverse;

    .filters-btn {
      margin-right: 0;
    }

    .transactions-radio {
      margin-bottom: 0.75rem;
    }
  }
}
</style>
