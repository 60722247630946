<template>
  <PageModal
    ref="addRequisitesModal"
    class="add-requisites-modal"
    modal-id="addRequisitesModal"
    :title="$t('page.requisites.title')"
    is-centered
  >
    <RequisitesForm ref="requisitesForm" v-model="form" />

    <template v-slot:footer>
      <div class="w-100 d-flex justify-content-between">
        <ButtonElement
          data-cy="find-with-filters"
          variant="primary"
          text="save"
          @click="onClickSubmit"
        />

        <ButtonElement
          data-cy="reset-filters"
          variant="outline-primary"
          text="back"
          @click="onClickBack"
        />
      </div>
    </template>
  </PageModal>
</template>

<script>
import PageModal from "@/components/modal/PageModal";
import RequisitesForm from "@/components/RequisitesForm.vue";
import ButtonElement from "@/components/ButtonElement";

export default {
  name: "AddRequisitesModal",

  components: {
    PageModal,
    RequisitesForm,
    ButtonElement,
  },

  props: {
    requisite: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        middleName: "",
        taxNumber: "",
        iban: "",
      },
    };
  },

  watch: {
    requisite: {
      handler: "getForm",
      immediate: true,
      deep: false,
    },
  },

  methods: {
    getForm() {
      if (Object.values(this.requisite).length) {
        this.form = { ...this.requisite };
      }
    },

    showModal() {
      this.$refs.addRequisitesModal.showModal();
    },

    closeModal() {
      this.$refs.addRequisitesModal.closeModal();
    },

    async onClickSubmit() {
      this.$refs.requisitesForm.emitForm();

      if (this.form.isValid) {
        this.$emit("saveRequisite", this.form);
        this.closeModal();
        this.form.isValid = false;
      }
    },

    onClickBack() {
      this.closeModal();
    },
  },
};
</script>
