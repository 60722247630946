import gql from "graphql-tag";

export const updateProfile = gql`
  mutation updateProfile(
    $firstName: String
    $lastName: String
    $middleName: String
    $taxNumber: String
    $iban: String
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      taxNumber: $taxNumber
      iban: $iban
    ) {
      firstName
      lastName
      detail {
        __typename
        ... on Agent {
          middleName
          taxNumber
          iban
        }
      }
    }
  }
`;

export const createWithdrawal = gql`
  mutation createWithdrawal {
    createWithdrawal {
      id
      amount
      status
      user {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;
