<template>
  <div>
    <KTCard card-class="card-table">
      <OurTable
        data-cy="withdrawals-table"
        table-classes="our-table table-responsive-xl"
        :pagination-data="paginationData"
        :items="agentWithdrawals"
        :fields="fields"
        :filters="appliedFilters"
        @pageChange="onPageChange"
      />
    </KTCard>

    <PageModal ref="filtersModal" modal-id="filtersModal" :title="$t('label.filters')" is-centered>
      <OurFilters
        ref="ourFilters"
        :fields="fieldsForFilters"
        :applied-filters="appliedFilters"
        @applyFilters="onApplyFilters"
      />

      <template v-slot:footer>
        <div class="w-100 d-flex justify-content-between">
          <ButtonElement
            data-cy="find-with-filters"
            variant="primary"
            text="find"
            @click="onFindWithFilters"
          />

          <ButtonElement
            data-cy="reset-filters"
            variant="outline-primary"
            text="resetFilters"
            @click="onResetFilters"
          />
        </div>
      </template>
    </PageModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { TYPE_VALUES } from "@/api/payments";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import PageModal from "@/components/modal/PageModal";
import OurFilters from "@/components/OurFilters";
import ButtonElement from "@/components/ButtonElement";

const { withdrawals } = TYPE_VALUES;

export default {
  name: "WithdrawalList",
  components: {
    KTCard,
    OurTable,
    PageModal,
    OurFilters,
    ButtonElement,
  },

  props: {
    paginationData: {
      type: Object,
      default: () => ({}),
    },

    withdrawals: {
      type: Array,
      default: () => [],
    },

    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "status",
          label: this.$t("label.status"),
          type: "status",
          tdAttr: {
            style: "width: 30%; max-width: 135px;",
          },
          filter: {
            component: "select",
            type: "status",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "amount",
          label: this.$t("label.amount"),
          thClass: "text-right",
          tdAttr: {
            style: "width: 15%; max-width: 110px; text-align: right;",
          },
          filter: {
            component: "input",
            type: "number",
            operator: "like",
            classes: "w-50",
          },
        },
        {
          key: "comment",
          label: this.$t("label.comment"),
          tdAttr: {
            style: "width: 40%; max-width: 110px; white-space: normal;",
          },
          filter: {
            component: "input",
            type: "text",
            operator: "like",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("withdrawal", ["statuses"]),
    ...mapState("currency", ["currency"]),

    agentWithdrawals() {
      return this.withdrawals.map((withdrawal) => ({
        dateCreate: withdrawal.createdAt,
        status: this.statuses.find((item) => item.code === withdrawal.status),
        amount: `${withdrawal.amount} ${this.currency}`,
        comment: withdrawal.comment || "–",
      }));
    },

    fieldsForFilters() {
      return this.fields.map((field) => {
        if (field.key === "status") {
          field.filter.selectOptions = this.statuses;
          field.filter.trackBy = "code";
        }

        return field;
      });
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    showFiltersModal() {
      this.$refs.filtersModal.showModal();
    },

    onFindWithFilters() {
      this.$refs.ourFilters.applyFilters();
      this.$refs.filtersModal.closeModal();
    },

    onApplyFilters(filters) {
      this.$emit("applyFilters", {
        type: withdrawals,
        fields: this.fields,
        filters,
      });
    },

    onResetFilters() {
      this.$emit("resetFilters", withdrawals);

      this.$refs.filtersModal.closeModal();
    },
  },
};
</script>
